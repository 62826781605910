<template>
  <component :is="clientData === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === null && !loading"
    >
      <h4 class="alert-heading">
        Erro ao recuperar os dados
      </h4>
      <div class="alert-body">
        Não encontramos os dados desta aula. Voltar para
        <b-link
          class="alert-link"
          :to="{ name: 'courses'}"
        >
          a lista das aulas.
        </b-link>
      </div>
    </b-alert>

    <!-- User Info: Input Fields -->
    <b-form
      v-if="clientData && !loading"
    >
      <b-row class="mb-1">
        <b-col>
          <h4>{{ clientData.professor_name ? `Aula com ${clientData.professor_name}` : 'Aula' }}</h4>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="success"
            @click="updateClient"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>

      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Modalidade"
            label-for="modality"
            :invalid-feedback="validation.modality.feedback"
            :state="validation.modality.state"
          >
            <v-select
              id="modality"
              v-model="clientData.modality"
              label="name"
              :options="$store.state.quales.modalities"
              :reduce="c => c.id"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Professor"
            label-for="professor"
            :invalid-feedback="validation.professor.feedback"
            :state="validation.professor.state"
          >
            <v-select
              id="professor"
              v-model="clientData.professor"
              label="full_name"
              :options="$store.state.quales.professors"
              :reduce="c => c.id"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Local"
            label-for="local"
            :invalid-feedback="validation.local.feedback"
            :state="validation.local.state"
          >
            <v-select
              id="local"
              v-model="clientData.local"
              label="full_name"
              :options="$store.state.quales.clients"
              :reduce="c => c.id"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Locais adicionais que podem participar"
            label-for="optional_locals"
            :invalid-feedback="validation.local.feedback"
            :state="validation.local.state"
          >
            <v-select
              id="optional_locals"
              v-model="clientData.optional_locals"
              label="full_name"
              :options="$store.state.quales.clients"
              :reduce="c => c.id"
              :clearable="true"
              :multiple="true"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Dia"
            label-for="day"
            :invalid-feedback="validation.day.feedback"
            :state="validation.day.state"
          >
            <v-select
              id="day"
              v-model="clientData.day"
              label="label"
              :options="dias"
              :reduce="c => c.value"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <label
            for="city"
          >Horário</label>
          <b-form-timepicker
            v-model="clientData.time"
            locale="pt-br"
            :minutes-step="5"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <label
            for="city"
          >Duração</label>
          <b-form-timepicker
            v-model="clientData.duration"
            locale="pt-br"
            :minutes-step="5"
          />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex my-1">
            <b-form-checkbox
              v-model="clientData.active"
              :value="true"
              :unchecked-value="false"
              switch
            />
            <p
              class="mb-0"
            >
              Aula Ativa
            </p>
          </div>
        </b-col>
      </b-row>

    </b-form>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BMedia, BButton,
  BRow, BCol, BForm, BAvatar, BFormTextarea, BFormCheckbox, BFormTimepicker, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BMedia,
    BButton,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BFormInvalidFeedback,

    vSelect,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      validation: {
        day: {
          value: null,
          feedback: '',
        },
        local: {
          value: null,
          feedback: '',
        },
        modality: {
          value: null,
          feedback: '',
        },
        professor: {
          value: null,
          feedback: '',
        },
      },
      validationBase: {
        day: {
          value: null,
          feedback: '',
        },
        local: {
          value: null,
          feedback: '',
        },
        modality: {
          value: null,
          feedback: '',
        },
        professor: {
          value: null,
          feedback: '',
        },
      },
      clientData: null,
      loading: true,
      dias: [
        {
          value: 0,
          label: 'Segunda-Feira',
        },
        {
          value: 1,
          label: 'Terça-Feira',
        },
        {
          value: 2,
          label: 'Quarta-Feira',
        },
        {
          value: 3,
          label: 'Quinta-Feira',
        },
        {
          value: 4,
          label: 'Sexta-Feira',
        },
        {
          value: 5,
          label: 'Sábado',
        },
        {
          value: 6,
          label: 'Domingo',
        },
      ],
      emptyClient: {
        duration: '01:00:00',
        day: null,
        local: null,
        optional_locals: [],
        modality: null,
        professor: null,
        time: '18:00:00',
        active: true,
      },
    }
  },
  beforeCreate() {
    this.$store.dispatch('quales/getClients')
    this.$store.dispatch('quales/getProfessors')
    this.$store.dispatch('quales/getModalities')
  },
  created() {
    if (this.userId !== -1) {
      this.$store.dispatch('courses/getClientById', this.userId).then(() => {
        this.$store.dispatch('quales/getModalities').then(() => {
          this.clientData = this.$store.state.courses.clientData
          this.loading = false
        })
      })
    } else {
      this.clientData = this.emptyClient
      this.loading = false
    }
  },
  methods: {
    updateClient() {
      this.$store.dispatch('courses/updateClient', this.clientData)
        .then(response => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          this.clientData.id = response.data.id
          this.updated = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aula',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.status === 200 ? 'Aula atualizada' : 'Aula criada',
            },
          })
          this.$router.push({ name: 'courses' })
        })
        .catch(error => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          /* eslint-disable */
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.validation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.validation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aula',
              icon: 'UserXIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar esses dados. Por favor verificar.',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
